@import url('https://fonts.googleapis.com/css?family=Noto+Sans:400,400i,700,700i&display=swap&subset=cyrillic,cyrillic-ext,latin-ext');


*{
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

html {
  font-size:62.5%;
}

body {
  margin: 0;
  font-family: 'Noto Sans',sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
}

.container{
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
}

.section {
  margin: 15rem auto;
}

main {
  overflow-x: hidden;
}

@media all and (max-width: 1200px) {

  html {
    font-size:52.5%;
  }

  .container {
    padding-left: 2rem;
    padding-right: 2rem;
  }
}

@media all and (max-width: 768px) {
  html {
    font-size: 42.5%;
  }
}
