.about {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.about__image {
    width: 48%;
}

.about__image img {
    width: 100%;
}

.about__info{
    width: 48%;
}

.about-text p{
    color: #333;
    font-size: 1.6rem;
    line-height: 1.8;
    margin-bottom: 1rem;
}

@media all and (max-width: 768px) {
    .about {
        flex-direction: column-reverse;
    }

    .about__image {
        width: 100%;
        position: relative;
    }

    .about__info {
        width: 100%;
        margin-bottom: 5rem;
    }

    .about-text{
        text-align: center;
    }

    .about-text p{
        font-size: 2rem;
    }
    
}