@import url(https://fonts.googleapis.com/css?family=Noto+Sans:400,400i,700,700i&display=swap&subset=cyrillic,cyrillic-ext,latin-ext);
.header-info {
    background-color: #381741;
    padding-bottom: 1rem;
    padding-top: 1rem;
}

.header-info__container{
    font-size: 1.4rem;
    color: white;
    display: flex;
    justify-content: flex-end;
}

.header-info_lang span:first-of-type {
    margin-right: .5rem;
    cursor: pointer;
}

.header-info_lang span:last-of-type {
    margin-left: .5rem;
    cursor: pointer;
}

.header-info_lang span {
    position: relative;
}

.header-info_lang span.active:after {
    content: '';
    position: absolute;
    width: 100%;
    height: 1px;
    bottom: 0;
    left: 0;
    background-color: white;
}

.header-info__contact {
    margin-right: 1.5rem;
}

.header-info__contact span{
    color: white;
}

.header-info__contact span:first-of-type{
    margin-right: 1rem;
}

.header-info__contact span a {
    color: #D1D5B6;
    text-decoration: none;
}

.header-info__contact span a:hover {
    color: #695E93;
}

.header-nav {
    padding-top: 2rem;
    padding-bottom: 2rem;
    display: flex;
    justify-content: space-between;
    position: relative;
}

.header-nav__logo {
    width: 14rem;
}

.header-nav__logo img {
    width: 100%;
}

.header-nav__navigation {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 40%;
}

.header-nav__navigation a{
    font-size: 1.8rem;
    text-decoration: none;
    color: #303437;
    font-weight: 500;
    position: relative;
    padding: 1rem 0;
    -webkit-transition: all ease .2s;
    transition: all ease .2s;
}

.header-nav__navigation a:before{
    content: '';
    position: absolute;
    width: 100%;
    height: 2px;
    background-color: #381741;
    top: 0;
    left: 0;
    display: none;
}

.header-nav__navigation a:hover{
    color: #381741;
}

.header-nav__navigation a:hover:before{
    display: block;
}

.header-nav-mobile {
    display: none;
}

.header-nav-mobile__navigation{
    display: none;
}

@media all and (max-width: 768px) {

    .header-nav__navigation {
        display: none;
    }

    .header-nav-mobile {
        display: block;
        cursor: pointer;
    }

    .header-nav-mobile__navigation {
        position: absolute;
        top: 100%;
        right: 0;
        z-index: 5;
        display: none;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        background-color: white;
        width: 100vw;
        padding: 4rem 0;
        box-shadow: 0px 14px 12px 0px rgba(0,0,0,0.76);
    }

    .header-nav-mobile__navigation.show {
        display: flex;
    }

    .header-nav-mobile__navigation a {
        font-size: 1.8rem;
        text-decoration: none;
        color: #303437;
        font-weight: 500;
        position: relative;
        padding: 1rem 0;
        margin: 1rem 0;
        -webkit-transition: all ease .2s;
        transition: all ease .2s;
    }

    .header-info__container {
        justify-content: space-between;
    }

    .header-info__contact {
        margin-right: 1.5rem;
        display: flex;
        flex-direction: column;
    }

    .menu-mob {
        font-size: 4rem;
        color: #303437;
    }
}
.footer {
    /* height: 50rem; */
    background-color: #381741;
    padding: 7rem 0;
}

.footer-container {
    color: white;
    display: flex;
}

.footer-col {
    width: 32%;
    margin: 0 4rem;

}

.footer-title {
    font-size: 2.2rem;
    color: white;
    font-weight: 600;
    margin-bottom: 4rem;
}

.footer-about__text p {
    font-size: 1.4rem;
    color: white;
    margin-bottom: 2rem;
    line-height: 1.6;
}

.footer-contact__info {
    font-size: 1.6rem;
    color: white;
    margin-bottom: 2rem;
}

.footer-contact__info span {
    color: #D1D5B6;
    font-style: italic;
}

.footer-contact__info span a {
    color: #D1D5B6;
    font-style: italic;
    text-decoration: none;
}

.footer-contact__info span a:hover {
    color: #695E93;
}

.footer-map iframe {
    width: 100%;
    height: 25rem;
}

.credit {
    color: white;
    border-top: 1px solid #695E93;
    padding-top: 2rem;
    text-align: center;
}

.credit a {
    color: #D1D5B6;
}

.credit a:hover {
    color: #695E93;

}

@media all and (max-width: 1040px) {
    .footer-about {
        display: none;
    }
}

@media all and (max-width: 1040px) {
    .footer-container {
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .footer-col {
        width: 100%;
        margin: 0 0 4rem 0;
    }
}
.main-title {
    display: none;
}

.slogan-slide {
    /* background-image: url('/images/slogan.jpg'); */
    height: 60rem;
    /* width: 100%; */
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    position: relative;
    z-index: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.slogan-slide:after{
    content: '';
    width: 100%;
    height: 100%;
    position:absolute;
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, .25);
    z-index:-1;
}

.slogan-text {
    font-size: 4rem;
    color: white;
    text-align: center;
    margin-bottom: 2rem;
    -webkit-animation: fadeIn 2s ease-in-out;
            animation: fadeIn 2s ease-in-out;
}

@-webkit-keyframes fadeIn {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

@keyframes fadeIn {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}
.section-title {
    font-size: 4.2rem;
    color: #381741;
    text-align: center;
    margin: 4rem 0;
}
.about {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.about__image {
    width: 48%;
}

.about__image img {
    width: 100%;
}

.about__info{
    width: 48%;
}

.about-text p{
    color: #333;
    font-size: 1.6rem;
    line-height: 1.8;
    margin-bottom: 1rem;
}

@media all and (max-width: 768px) {
    .about {
        flex-direction: column-reverse;
    }

    .about__image {
        width: 100%;
        position: relative;
    }

    .about__info {
        width: 100%;
        margin-bottom: 5rem;
    }

    .about-text{
        text-align: center;
    }

    .about-text p{
        font-size: 2rem;
    }
    
}
.services-list {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
}

.services-item {
    display: flex;
    align-items: center;
    width: 30%;
    height: 12rem;
    border: 1px solid #CCCAAE;
    border-radius: 10px;
    padding: 0 2rem;
    margin: 1.5rem;
}

.services-item__icon {
    width: 6rem;
    margin-right: 2.5rem;
}

.services-item__title {
    font-size: 1.6rem;
    color: #3D3C41;
}

@media all and (max-width: 1040px) {
    .services-item {
        width: 45%;
    }

    .services-item__title {
        font-size: 2rem;
    }
}

@media all and (max-width: 545px) {
    .services-item {
        width: 98%;
    }
}
.team-items {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
}

.team-item {
    width: 20%;
    margin-bottom: 5rem;
}

.team-item__icon {
    text-align: center;
    font-size: 12rem;
}

.team-item__image {
    width: 13rem;
    height: 13rem;
    margin: 0 auto;
   
}

.team-item__image img{
    width: 100%;
    height: 100%;
    object-fit:cover;
    border-radius:50%;
}

.team-item__info {
    margin-top: 2rem;
    text-align: center;
}

.team-item__name {
    font-size: 1.8rem;
    color: rgb(43, 41, 42);
    margin-bottom: 1rem;
}

.team-item__function {
    font-size: 1.6rem;
    color: #3D393B;
    font-style: italic;
    margin-bottom: 1rem;
}

.team-item__contact {
    font-size: 1.6rem;
    color: #381741;
    font-weight: bold;
}

@media all and (max-width: 768px) {
    .team-item {
        width: 48%;
    }
}

@media all and (max-width: 500px) {
    .team-item {
        width: 100%;
    }
}
*{
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

html {
  font-size:62.5%;
}

body {
  margin: 0;
  font-family: 'Noto Sans',sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
}

.container{
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
}

.section {
  margin: 15rem auto;
}

main {
  overflow-x: hidden;
}

@media all and (max-width: 1200px) {

  html {
    font-size:52.5%;
  }

  .container {
    padding-left: 2rem;
    padding-right: 2rem;
  }
}

@media all and (max-width: 768px) {
  html {
    font-size: 42.5%;
  }
}

