.team-items {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
}

.team-item {
    width: 20%;
    margin-bottom: 5rem;
}

.team-item__icon {
    text-align: center;
    font-size: 12rem;
}

.team-item__image {
    width: 13rem;
    height: 13rem;
    margin: 0 auto;
   
}

.team-item__image img{
    width: 100%;
    height: 100%;
    object-fit:cover;
    border-radius:50%;
}

.team-item__info {
    margin-top: 2rem;
    text-align: center;
}

.team-item__name {
    font-size: 1.8rem;
    color: rgb(43, 41, 42);
    margin-bottom: 1rem;
}

.team-item__function {
    font-size: 1.6rem;
    color: #3D393B;
    font-style: italic;
    margin-bottom: 1rem;
}

.team-item__contact {
    font-size: 1.6rem;
    color: #381741;
    font-weight: bold;
}

@media all and (max-width: 768px) {
    .team-item {
        width: 48%;
    }
}

@media all and (max-width: 500px) {
    .team-item {
        width: 100%;
    }
}