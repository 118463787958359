.main-title {
    display: none;
}

.slogan-slide {
    /* background-image: url('/images/slogan.jpg'); */
    height: 60rem;
    /* width: 100%; */
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    position: relative;
    z-index: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.slogan-slide:after{
    content: '';
    width: 100%;
    height: 100%;
    position:absolute;
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, .25);
    z-index:-1;
}

.slogan-text {
    font-size: 4rem;
    color: white;
    text-align: center;
    margin-bottom: 2rem;
    animation: fadeIn 2s ease-in-out;
}

@keyframes fadeIn {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}