.services-list {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
}

.services-item {
    display: flex;
    align-items: center;
    width: 30%;
    height: 12rem;
    border: 1px solid #CCCAAE;
    border-radius: 10px;
    padding: 0 2rem;
    margin: 1.5rem;
}

.services-item__icon {
    width: 6rem;
    margin-right: 2.5rem;
}

.services-item__title {
    font-size: 1.6rem;
    color: #3D3C41;
}

@media all and (max-width: 1040px) {
    .services-item {
        width: 45%;
    }

    .services-item__title {
        font-size: 2rem;
    }
}

@media all and (max-width: 545px) {
    .services-item {
        width: 98%;
    }
}