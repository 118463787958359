.footer {
    /* height: 50rem; */
    background-color: #381741;
    padding: 7rem 0;
}

.footer-container {
    color: white;
    display: flex;
}

.footer-col {
    width: 32%;
    margin: 0 4rem;

}

.footer-title {
    font-size: 2.2rem;
    color: white;
    font-weight: 600;
    margin-bottom: 4rem;
}

.footer-about__text p {
    font-size: 1.4rem;
    color: white;
    margin-bottom: 2rem;
    line-height: 1.6;
}

.footer-contact__info {
    font-size: 1.6rem;
    color: white;
    margin-bottom: 2rem;
}

.footer-contact__info span {
    color: #D1D5B6;
    font-style: italic;
}

.footer-contact__info span a {
    color: #D1D5B6;
    font-style: italic;
    text-decoration: none;
}

.footer-contact__info span a:hover {
    color: #695E93;
}

.footer-map iframe {
    width: 100%;
    height: 25rem;
}

.credit {
    color: white;
    border-top: 1px solid #695E93;
    padding-top: 2rem;
    text-align: center;
}

.credit a {
    color: #D1D5B6;
}

.credit a:hover {
    color: #695E93;

}

@media all and (max-width: 1040px) {
    .footer-about {
        display: none;
    }
}

@media all and (max-width: 1040px) {
    .footer-container {
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .footer-col {
        width: 100%;
        margin: 0 0 4rem 0;
    }
}