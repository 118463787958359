.header-info {
    background-color: #381741;
    padding-bottom: 1rem;
    padding-top: 1rem;
}

.header-info__container{
    font-size: 1.4rem;
    color: white;
    display: flex;
    justify-content: flex-end;
}

.header-info_lang span:first-of-type {
    margin-right: .5rem;
    cursor: pointer;
}

.header-info_lang span:last-of-type {
    margin-left: .5rem;
    cursor: pointer;
}

.header-info_lang span {
    position: relative;
}

.header-info_lang span.active:after {
    content: '';
    position: absolute;
    width: 100%;
    height: 1px;
    bottom: 0;
    left: 0;
    background-color: white;
}

.header-info__contact {
    margin-right: 1.5rem;
}

.header-info__contact span{
    color: white;
}

.header-info__contact span:first-of-type{
    margin-right: 1rem;
}

.header-info__contact span a {
    color: #D1D5B6;
    text-decoration: none;
}

.header-info__contact span a:hover {
    color: #695E93;
}

.header-nav {
    padding-top: 2rem;
    padding-bottom: 2rem;
    display: flex;
    justify-content: space-between;
    position: relative;
}

.header-nav__logo {
    width: 14rem;
}

.header-nav__logo img {
    width: 100%;
}

.header-nav__navigation {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 40%;
}

.header-nav__navigation a{
    font-size: 1.8rem;
    text-decoration: none;
    color: #303437;
    font-weight: 500;
    position: relative;
    padding: 1rem 0;
    transition: all ease .2s;
}

.header-nav__navigation a:before{
    content: '';
    position: absolute;
    width: 100%;
    height: 2px;
    background-color: #381741;
    top: 0;
    left: 0;
    display: none;
}

.header-nav__navigation a:hover{
    color: #381741;
}

.header-nav__navigation a:hover:before{
    display: block;
}

.header-nav-mobile {
    display: none;
}

.header-nav-mobile__navigation{
    display: none;
}

@media all and (max-width: 768px) {

    .header-nav__navigation {
        display: none;
    }

    .header-nav-mobile {
        display: block;
        cursor: pointer;
    }

    .header-nav-mobile__navigation {
        position: absolute;
        top: 100%;
        right: 0;
        z-index: 5;
        display: none;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        background-color: white;
        width: 100vw;
        padding: 4rem 0;
        box-shadow: 0px 14px 12px 0px rgba(0,0,0,0.76);
    }

    .header-nav-mobile__navigation.show {
        display: flex;
    }

    .header-nav-mobile__navigation a {
        font-size: 1.8rem;
        text-decoration: none;
        color: #303437;
        font-weight: 500;
        position: relative;
        padding: 1rem 0;
        margin: 1rem 0;
        transition: all ease .2s;
    }

    .header-info__container {
        justify-content: space-between;
    }

    .header-info__contact {
        margin-right: 1.5rem;
        display: flex;
        flex-direction: column;
    }

    .menu-mob {
        font-size: 4rem;
        color: #303437;
    }
}